import React from "react"

import Layout from "../components/global/layout"
import SEO from "../components/global/seo"

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <section className="container-left container-right privacy">
      <h1>TERMS AND CONDITIONS</h1>
      <p>
        This website has been developed by Blistex Inc, to comply with UK
        legislation and the materials on it are directed at consumers, health
        professionals and businesses who access the website from the UK. Those
        who access this site from other locations are responsible for compliance
        with local laws if and to the extent that local laws are applicable.
      </p>

      <p>
        Blistex Inc makes no representations that any of the products referred
        to on this website are appropriate for use, or available, in other
        locations. Where any of these products are available in other locations
        they may be subject to different regulatory requirements depending on
        the country of use. Those who access this site from other locations
        should always consult their local prescribing information before using
        any of the products referred to on this website. Certain areas of this
        website may be stated to be for a particular group of users only (e.g.
        health professionals) and the information contained in those areas
        should be interpreted accordingly.
      </p>
      <p>
        Blistex Inc has exercised every reasonable care in the preparation of
        this website. However, to the extent permitted by applicable law:
      </p>
      <p>
        a. the materials on this website are provided without any warranties,
        express or implied, as to the accuracy of any information contained in
        any such materials;
      </p>
      <p>
        b. Blistex Inc disclaims all liability for any loss or damage which may
        arise from the use of any information contained in any of the materials
        on this website. For the avoidance of doubt, such exclusion of liability
        shall not apply to any loss or damage arising from death or personal
        injury that is caused by negligence on the part of Blistex Inc.
      </p>

      <p>
        This website contains some general information on a number of health
        topics. However, it does not contain all relevant information on such
        topics. Consequently, such information should not be construed as giving
        medical advice. Users of the website who are seeking medical advice for
        a complaint which is referred to on this website should always consult
        an appropriate healthcare professional. Blistex Inc has taken every
        reasonable care to ensure that the information on the products shown on
        this website, and the instructions for their use, are accurate and up to
        date. However, medical information is constantly changing and therefore
        users of this website who are considering using any of the products
        shown thereon should always consult an appropriate healthcare
        professional and also read the product information on the pack itself
        before proceeding with any such use.
      </p>
      <h2>LINKS TO EXTERNAL WEBSITES</h2>

      <p>
        We may refer to, or establish relations with, other companies,
        organisations and public bodies that will enable you to access their
        websites directly from ours. Each company, organisation or public body
        will operate its own policy regarding the use of cookies and collection
        and use of information. If you have any concerns regarding the way in
        which your data will be used, you are advised to read the Privacy
        Statement on the relevant website.
      </p>
      <p>
        We will try to provide you with links to high quality, reputable
        websites that we think will be of interest and relevance to you.
        However, please note that Blistex Ltd is not responsible for the content
        of such websites. We cannot accept responsibility for any issues arising
        in connection with the third party's use of your data, the website
        content or the services offered to you by these websites.
      </p>
      <p>
        No links to this website may be included in any other website without
        Blistex Inc express written authorisation.
      </p>
      <h2>REGISTERED ADDRESS:</h2>
      <p>
        <address>
          Blistex Inc.
          <br /> 1800 Swift Drive
          <br /> Oak Brook
          <br /> IL
          <br /> USA
          <br /> 60523-1574
          <br /> Registered in the USA
        </address>
      </p>
    </section>
  </Layout>
)

export default TermsAndConditions
